import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { createStory } from '../../actions/stories';
import ReactMde from 'react-mde';
import Showdown from "showdown";
import "react-mde/lib/styles/css/react-mde-all.css";


const converter = new Showdown.Converter({
  tables: true,
  simplifiedAutoLink: true,
  strikethrough: true,
  tasklists: true
});


const Form = ({story}) => {
  let parent = story
  let parentId 
  let parentlineage
  const navigate = useNavigate()
  const [selectedTab, setSelectedTab] =useState("write");
  const [formMessage, setFormMessage] = useState();

  const [tagInput, setTagInput] = useState([]);
  const [storyData, setStoryData] = useState({
    lineage: [],parent : '', title: '', body: '', tags: [], nsfw:false
  });  
  const user = JSON.parse(localStorage.getItem('profile'));
  useEffect(() => {
    if (!parent){
      parentId = ''
      parentlineage = []
    }else{
      parentId = parent._id
      parentlineage = [].concat(parent.lineage)
      parentlineage.push(parent._id)
    }
    setStoryData({lineage: parentlineage, parent : parentId, title: '', body: '', tags: [], nsfw:false})
  }, [story])

  const handleAdd = (e)=>{
    if(e.keyCode === 13){
      setStoryData({ ...storyData, tags: [...storyData.tags,tagInput.slice(0,-1).trim()]})
      setTagInput('')
    }
  }

  const handleDelete = (tagToDelete)=>{
    if(tagToDelete){
      setStoryData({ ...storyData, tags: storyData.tags.filter((tag) => tag !== tagToDelete)})
    }

  }

  const updateStoryBody = (e) => {
    if(e.split(" ").length < 1000){
      setStoryData({ ...storyData, body: e})
    }else{
      setFormMessage("Word Limit of 1000 Reached");
    }


  }

  const dispatch = useDispatch();

  const handleSubmit = (e) =>{
    
    e.preventDefault();
    if(storyData.body.length && storyData.title.length){
      console.log(storyData);
      console.log(storyData.tags);
      dispatch(createStory({ ...storyData, name: user?.result?.name}));
      clear()
      navigate('/browse')
    }else{
      setFormMessage("Please write your Title and Story before submitting")
    }

  };

  if(!user?.result?.name){
    return (
      <div className="container">
        <h4>Please log in to write a story.</h4>
      </div>
    )
  }
  console.log(storyData)
  const addNSFWTag = () => {
    setStoryData({...storyData, nsfw: !storyData.nsfw})
  }

  const clear = (e) =>{
    setStoryData({lineage: parentlineage, parent : parentId, title: '', body: '', tags: '',nsfw:false});
  }




  return (
    <div className='container'>
      <form autoComplete='off' noValidate className='form' onSubmit={handleSubmit}>
        {parent
        ?  <>
        <h4>Continue the Story...</h4>
        <p>Write a sequel to <b>{parent.title}</b></p>
        </>
        :  <h4>Write a New Story</h4>
      }
        <h4 className="error" style={{margin:"0 0 1em 0"}}>{formMessage}</h4>

        <label htmlFor="title"> Title:   </label>
        <input type="text" name="title" required value={storyData.title} onChange={(e) => setStoryData({ ...storyData, title: e.target.value})}/>
        
        <label htmlFor="story"> Story: </label>
        <ReactMde
        value={storyData.body}
        onChange={(e) => updateStoryBody(e) }
        selectedTab={selectedTab}
        onTabChange={setSelectedTab}
        toolbarCommands = {[["bold", "italic"]]}
        generateMarkdownPreview={markdown =>
          Promise.resolve(converter.makeHtml(markdown))}
        />

          <div style={{display: 'flex',alignItems: 'center', justifyContent: 'space-between'}}>
            <div>
              <input type="checkbox" name="nsfw" onChange={addNSFWTag}/>
              <label htmlFor="nsfw" >NSFW </label >
              </div>
              <p style={{opacity: 0.6}}>{storyData.body.split(" ").length-1}/1000</p>

          </div>
        <label htmlFor="tags"> Tags: </label>
        <textarea  className="tags" name="searchTags"  value={tagInput} onChange={(e)=> setTagInput(e.target.value)} onKeyUp={handleAdd}/>
          <div></div>
          <div style={{display:"flex", flexWrap:"wrap", width:"100%", gap:"6px", padding:"0 0 1em 5px "}}>
          {storyData.tags.map((tag) =>
          <button style={{width:"30%", border:"1px solid #ccc", borderRadius:"1em", padding:"6px", cursor:"pointer"}} onClick={() => handleDelete(tag)}>
           <a >{tag} x</a>
          </button> 
          )}
          </div>

        <input className="button secondary" type="submit" value="Submit"/>
              
      </form>

    </div>
  )
}

export default Form