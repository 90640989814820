
export default (stories = [], action) => {
    switch (action.type){
        case 'FETCH_ALL':
            return action.payload;
        case 'FETCH_BY_SEARCH':
            return action.payload;
        case 'FETCH':
            return action.payload;
        case 'LIKE':
            return action.payload;
        case 'LOCKED':
            return action.payload;
        case 'CREATE':
            return [...stories, action.payload];
        case 'DELETE':
            return stories.filter((story) => story._id !== action.payload)
        default:
            return stories
    }

}

