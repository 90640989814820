import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";


import { Navbar } from './components/Navbar/Navbar';
import { Footer } from './components/Footer/Footer';
import Home from './components/Home/Home';
import Auth from './components/Auth/Auth';
import About from './components/About/About';
import AddNew from "./components/AddNew/AddNew";
import Browse from "./components/Browse/Browse";
import StoryTree from "./components/StoryTree/StoryTree";
import Dashboard from "./components/Dashboard/Dashboard";
import ResetPassword from "./components/Auth/ResetPassword";
import UpdatePassword from "./components/Auth/UpdatePassword";

function App() {
  return (
    <BrowserRouter>
        <Navbar/>
        <Routes>
          <Route path ="/" exact element={<Home />}/>
          <Route path ="/auth" exact element={<Auth />}/>
          <Route path ="/resetpassword" exact element={<ResetPassword/>}/>
          <Route path ="/resetpassword/:token" exact element={<UpdatePassword/>}/>
          <Route path ="/about" exact element={<About />}/>
          <Route path ="/create" exact element={<AddNew />}/>
          <Route path ="/browse" exact element={<Browse />}/>
          <Route path ="/dashboard" exact element={<Dashboard/>}/>
          <Route path ="/story/:id" exact element={<StoryTree/>}/>
        </Routes>
        <Footer/>
    </BrowserRouter>
  );
}

export default App;
