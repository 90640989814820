import React from 'react'
import { Link } from 'react-router-dom'

export const Footer = () => {
  return (
     <footer>
      <div className="footer-banner">
         <div>
            <h2>Scrirrel</h2>
            <h4 className='anim-type anim'>A Collaborative Writing Experience</h4>
         </div>
      </div>
      <div className="footer">
         <div className='footer-cols'>
            <div>
                <h3>Site Links</h3>
                <Link className="nav-link" to="/" >
                    <p>Home</p>
                </Link>
                <Link className="nav-link" to="/about" >
                    <p >About</p>
                </Link>
                <Link className="nav-link" to="/browse" >
                    <p >Browse</p>
                </Link>
                <Link className="nav-link" to="/create" >
                    <p >Create</p>
                  </Link>
            </div>

   
         </div>
         <div className="donate-links">
             <h3>Support Us</h3>
            <a href="https://ko-fi.com/scrirrel" target="_blank">Donate on Ko-fi</a>
            <a href="https://patreon.com/scrirrel" target="_blank">Support us on Patreon</a>
         </div>
         <div className="copy">
            <span>&copy; Scrirrel </span>
         </div>
     </div>
     </footer>

  )
}
