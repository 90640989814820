import * as api from '../api';

//Action Creators
export const getStories = () => async (dispatch) => {
    try{
        const { data } = await api.fetchStories();
        dispatch({ type: 'FETCH_ALL', payload: data});


    }catch (error){
        console.log(error.message)
    }
}


export const getStoriesBySearch = (searchQuery) => async (dispatch) => {
  try{
      console.log(searchQuery);
      const { data: {data}} = await api.fetchStoriesBySearch(searchQuery);
 
      dispatch({ type: 'FETCH_BY_SEARCH', payload: data});


  }catch (error){
      console.log(error.message)
  }
}


export const getChildren = (parentId) => async (dispatch) => {
    try{
        const { data } = await api.fetchChildren(parentId);
        dispatch({ type: 'FETCH_ALL', payload: data});

    }catch (error){
        console.log(error.message)
    }
}

export const getStory = (storyId) => async (dispatch) => {
    try{
        const { data } = await api.fetchStory(storyId);
        dispatch({ type: 'FETCH', payload: data});
    }catch (error){
        console.log(error.message)
    }
}



export const createStory = (story) => async (dispatch) => {
    try {
        const { data } = await api.createStory(story);

        dispatch({ type : 'CREATE', payload: data});
    } catch (error) {
        console.log(error.message)
    }
}

export const likeStory = (id) => async (dispatch) => {
    try {
      const { data } = await api.likeStory(id);
  
      dispatch({ type: 'LIKE', payload: data });
    } catch (error) {
      console.log(error.message);
    }
  };

  export const deleteStory = (id) => async (dispatch, history) => {
    try {

      await api.deleteStory(id);

      dispatch({ type: 'DELETE', payload: id });

    } catch (error) {
      console.log(error.message);
    }
  };

  export const lockStory = (id) => async (dispatch) => {
    try {
      await api.lockStory(id);
  
      dispatch({ type: 'Lock', payload: id });
    } catch (error) {
      console.log(error.message);
    }
  };