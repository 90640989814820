import React from 'react'


const WelcomeBanner = ({message,big}) => {
  return (
    <div className='banner' >
        <div >
          {big ? <h2>{big}</h2>
          : <h2 >Scrirrel</h2>}
 
          {!big ? <h4 className='anim-type anim'>{message? message : "A Collaborative Writing Experience" }</h4>
          : <></>}

        </div>

    </div>
  )
}

export default WelcomeBanner