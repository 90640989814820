import React, { useState, useEffect } from 'react'
import { useDispatch } from 'react-redux';
import Stories from '../Stories/Stories'
import { getStories } from '../../actions/stories';
import WelcomeBanner from '../WelcomeBanner/WelcomeBanner';
const Browse = () => {
  const [currentId, setCurrentId] = useState(0);

  const dispatch = useDispatch();
  
  useEffect(() => {
    dispatch(getStories());
  }, [currentId, dispatch]);
  return (
    <>
    <WelcomeBanner message={"Find an interesting story..."}/>
    <div className='row-section'>
        <Stories/>
    </div>
    </>

  )
}

export default Browse