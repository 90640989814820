import React, {useEffect, useState} from 'react'
import { useDispatch } from 'react-redux';
import { useNavigate} from 'react-router-dom';
import { resetpassword } from '../../actions/auth';
import WelcomeBanner from '../WelcomeBanner/WelcomeBanner';


const ResetPassword = () => {
    const [email, setEmail] = useState('');

    const dispatch = useDispatch();
    const history = useNavigate();

    const handleSubmit = (e) => {
        e.preventDefault();
        
        dispatch(resetpassword(email,history))

      };


  return (
    <>
    <WelcomeBanner/>
    <div className='row-section'>
      <div className='container'>

        <h2 >Reset Password</h2>

        <form  onSubmit={handleSubmit}>
          <div >
            <div style={{display:"flex", gap:"0.5em"}}>
              <input name="email" placeholder="Email Address" onChange={(e) => setEmail(e.target.value)} type="email" />
            </div>
    
          </div>
           

          <button className="button secondary" style={{width:"100%"}} type="submit" >Reset Password</button>

        </form>

      </div>
  </div>
  </>
  )
}

export default ResetPassword