
export default (comments = [], action) => {
    switch (action.type){
        case 'FETCH_ALL':
            return action.payload;
        case 'FETCH':
            return action.payload;
        case 'LIKE':
            return action.payload;
        case 'LOCKED':
            return action.payload;
        case 'CREATE':
            return [...comments, action.payload];
        case 'DELETE':
            return comments.filter((comment) => comment._id !== action.payload)
        default:
            return comments
    }

}

