import React, {useRef} from 'react'
import { Link } from 'react-router-dom'
import {FaChevronDown} from 'react-icons/fa'


import WelcomeBanner from '../WelcomeBanner/WelcomeBanner'
import Logo from '../../img/logo.png';

const About = () => {
  const teamRef = useRef(null);
  const executeScroll = () => teamRef.current.scrollIntoView()    

  return (
    <>
      <WelcomeBanner></WelcomeBanner>
      <div className='row-section' style={{padding:"6em "}}>
        <div >
        <div className="container about-scrirrel" >
           <h2 style={{textAlign:"center"}}><img  className="logo" src={Logo} alt='Scrirrel Logo'/></h2>
          <h2 style={{textAlign:"center"}}>What is Scrirrel?</h2>
          <h4>Scrirrel is a Collaborative Writing Platform - and anyone can contribute!</h4>
          <p>Scrirrel is a place for writers to create interesting stories together.</p>
          <p>Once you've written your first chapter, other writers will be able to respond with their own sequels.</p>
          <p>You can support us on <a  style={{textDecoration:'none', fontWeight:'bold'}} className="secondary" href="https://ko-fi.com/scrirrel" target="_blank">Ko-fi</a> or <a  style={{textDecoration:'none', fontWeight:'bold'}} className="primary" href="https://patreon.com/scrirrel" target="_blank">Patreon</a>.</p>
            <br />
          <h4><Link to='/create' style={{textDecoration:'none'}} className="primary" >Create your story</Link> or <Link to='/browse' style={{textDecoration:'none'}} className="secondary" >find a story</Link>, and write a sequel.</h4>
       <div className="chevron-link" style={{textAlign:"center"}}>
       <Link  to="" onClick={executeScroll}><FaChevronDown/></Link>

       </div>

        </div>

        </div>
      </div>
      <hr className="handsome"></hr>
      <div className="row-section" style={{padding:"6em"}} ref={teamRef}>
          <div className="container about-card">
          <img className="avatar" src={require("../../img/banner03.jpg")} alt="Gingernut's Avatar" />
            <h3>Gingernut</h3>
            <p>I'm Gingernut - and I'm a squirrel!</p>
            <p>I spend my days exploring the forest and writing epic tales.</p>
          </div>
          <div className="container about-card">
            <img className="avatar" src={require("../../img/marshall.png")} alt="Calum's Avatar" />
            <h3>Calum</h3>
            <p>Hi, I'm Calum - I created Scrirrel!</p>
            <p>I wanted to create an app where people can get together and write truly collaborative stories and users can respond to their favourite writers!</p>
            
          </div>
          <div className="container about-card">
          <img className="avatar" src={require("../../img/banner01.jpg")} alt="Mystery's Avatar" />
            <h3>Aspen</h3>
            <p>Hi, I'm Aspen - I write stories for Scrirrel!</p>
            <p>My favourite genres are sci-fi and fantasy, and I also enjoy classic literature.</p>
          </div>

      </div>
    </>

  )
}

export default About