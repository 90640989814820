import React, {useEffect, useState} from 'react'
import { useDispatch } from 'react-redux';

import { useNavigate, useLocation, Link } from 'react-router-dom';
import { signup, login } from '../../actions/auth';
import WelcomeBanner from '../WelcomeBanner/WelcomeBanner';

const initialState = { username: '', email: '', password: '', confirmPassword: '' };


const Auth = () => {
  const location = useLocation();
  const [form, setForm] = useState(initialState);
  const [isSignup, setIsSignup] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  useEffect(() =>{
    if(location?.state?.direct == "signup"){
      setIsSignup(true);
    }else{
      setIsSignup(false);
    }
  }, [location])


  const dispatch = useDispatch();
  const history = useNavigate();

  const [showPassword, setShowPassword] = useState(false);



  const switchMode = () => {
    setForm(initialState);
    setIsSignup((prevIsSignup) => !prevIsSignup);
    setShowPassword(false);
    setErrorMessage('');
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    
    if(isSignup){
      dispatch(signup(form, history, setErrorMessage));
    }else{
      dispatch(login(form, history, setErrorMessage));

    }
  };



  const handleChange = (e) => {
    setForm({ ...form, [e.target.name]: e.target.value })

  };

  const forgotPassword = (e) => setForm({ ...form, [e.target.name]: e.target.value });


  return (
    <>
    <WelcomeBanner/>
    <div className='row-section'>
      <div className='container'>

        <h2 >{ isSignup ? 'Sign up' : 'Log In' }</h2>
        {errorMessage && <h4 className='error'>{errorMessage}</h4>}
        <form  onSubmit={handleSubmit}>
          <div >
            <div style={{display:"flex", gap:"0.5em"}}>
              { isSignup && (
              <>
                <input name="username" placeholder="Username" onChange={handleChange} type="text"/>
              </>
              )}
              <input name="email" placeholder="Email Address" onChange={handleChange} type="email" />
            </div>
            <div style={{display:"flex", gap:"0.5em"}}>
              <input name="password" placeholder="Password" onChange={handleChange} type={showPassword ? 'text' : 'password'}  />
              { isSignup && <input name="confirmPassword" placeholder="Repeat Password" onChange={handleChange} type="password" /> }
            </div>
          </div>
           

          <button className="button secondary" style={{width:"100%"}} type="submit" >
            { isSignup ? 'Sign Up' : 'Log In' }
          </button>

        </form>

        <div style={{display:"flex", justifyContent:"space-between"}}>
            <button style={{background:"none", border:"none", fontWeight:"bold"}} onClick={switchMode}>
               <Link to='/resetpassword' onClick={forgotPassword} style={{ fontWeight:"bold", textDecoration:"none", color:"inherit"}}> {!isSignup &&  "Forgot password?" } </Link>

            </button>
            <button style={{background:"none", border:"none", fontWeight:"bold"}} onClick={switchMode}>
              { isSignup ? 'Already have an account? Log in' : "Don't have an account? Sign Up" }
            </button>
        </div>

      </div>
  </div>
  </>
  )
}

export default Auth