import * as api from '../api';

//Action Creators
export const getComments = (storyID) => async (dispatch) => {
    try{
        const { data } = await api.fetchComments(storyID);
        dispatch({ type: 'FETCH_ALL', payload: data});


    }catch (error){
        console.log(error.message)
    }
}




export const createComment = (comment) => async (dispatch) => {
    try {
        console.log(comment)
        const { data } = await api.createComment(comment);

        dispatch({ type : 'CREATE', payload: data});
    } catch (error) {
        console.log(error.message)
    }
}

export const deleteComment = (id) => async (dispatch, ) => {
try {

    await api.deleteComment(id);

    dispatch({ type: 'DELETE', payload: id });

} catch (error) {
    console.log(error.message);
}
};

