import React, { useState, useEffect } from 'react'
import { useDispatch } from 'react-redux';


import WelcomeBanner from '../WelcomeBanner/WelcomeBanner';
import Stories from '../Stories/Stories';
import Form from '../Form/Form';
import { getStories } from '../../actions/stories';

const Home = () => {
  const [currentId, setCurrentId] = useState(0);

  const dispatch = useDispatch();
  
  useEffect(() => {
    dispatch(getStories());
  }, [currentId, dispatch]);
  return (
    <div>
      <WelcomeBanner/>
      <div className='row-section'>
        <Stories/>
      </div>
      <hr className="handsome"></hr>
      <div className='row-section'>
        <Form/>
      </div>
      
    </div>
  )
}

export default Home