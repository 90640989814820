import axios from 'axios';

const API = axios.create({ baseURL : 'https://scrirrel-db.herokuapp.com/'})

API.interceptors.request.use((req) => {
    if(localStorage.getItem('profile')){
        req.headers.Authorization = `Bearer ${JSON.parse(localStorage.getItem('profile')).token}`
    }

    return req;
});

export const fetchStories = () => API.get('/stories');
export const fetchStoriesBySearch = (searchQuery) => API.get(`/stories/search?searchQuery=${searchQuery.search || 'none'}&tags=${searchQuery.tags}`);
export const fetchChildren = (id) => API.get(`/stories/children/${id}`);
export const createStory = (newStory) => API.post('/stories', newStory);
export const fetchStory = (id) => API.get(`/stories/${id}`);
export const likeStory = (id) => API.patch(`/stories/${id}/liked`);
export const deleteStory = (id) => API.delete(`/stories/${id}`);
export const lockStory = (id) => API.patch(`/stories/${id}/locked`);

export const logIn = (form) => API.post('/user/login/', form);
export const signUp = (form) => API.post('/user/signup/', form);
export const updateInfo = (updated) => API.patch('/user/update/', updated); 
export const resetPassword = (email) => API.post('/user/resetpassword',email);
export const updatePassword = (updated) => API.post('/user/updatepassword',updated);

export const fetchComments = (id) => API.get(`/comments/${id}`); 
export const createComment = (comment) => API.post('/comments', comment);
export const deleteComment = (id) => API.delete(`/comments/${id}`);

