import React, { useEffect, useState } from 'react'
import ReactMarkdown from 'react-markdown'
import { useSelector , useDispatch} from 'react-redux'
import { Link } from 'react-router-dom'
import { FaTrashAlt, FaThumbsUp } from 'react-icons/fa';
import moment from 'moment'
import './style.css'

import { likeStory, deleteStory } from '../../../actions/stories'
import UserView from './UserView';



import Logo from '../../../img/logo.png';
import NSFW from '../../../img/NSFW.png';
import Beginning from '../../../img/book.png';


const StoryView = ({id, current, setRootStory}) => {
  const dispatch = useDispatch();
  const user = JSON.parse(localStorage.getItem('profile'));
  const [story, setStory] = useState();
  const [isLoaded, setIsLoaded] = useState(false);
  const [modal, setModal] = useState(false);



  useEffect(() => {
    fetch(`https://scrirrel-db.herokuapp.com/stories/${id}`).then(res => res.json())
    .then(
      (result) => {
        setIsLoaded(true);
        setStory(result)

      },
      (error) =>{
        console.log(error)
      }
    )
  }, [])


  const Likes = () => {
    if (story.likes.length > 0) {
      return story.likes.find((like) => like === user?.result?._id)
        ? (
          <>

          <span className='highlighted'>{story.likes.length > 2 ? `You and ${story.likes.length - 1} others` : `${story.likes.length}` }</span>

          
          </>
        ) : (
          <>        
          <span className='highlighted'>{story.likes.length}</span>
          </>
        );
    }

    return <><span className='highlighted'>{story.likes.length}</span></>;
  };



  return (
    <>

     {!isLoaded ? 
          <div className="loader">
            <div></div>
            <div></div>
            <div></div>
          </div>
      : 


        <div className='storyview-section' >
          {!story && 
          <div className="storyview-story" style={{textAlign:'center'}}>
            <p>There was a story here...</p>
            <p>It's gone now</p>
          </div>
          
          }
           
           {story && 
           <>
           <div >


            <div className="storyview-story">


                <div style={{display: 'flex', justifyContent: 'space-between'}}>

                <h3><Link to={`/story/${story._id}`} onClick={() => setRootStory(story)} style={{ textDecoration: 'none', color:'inherit' }}>{story.title} </Link></h3>

                  <div>
                    {story.author === "62f62dc4882fb82cac8ffa61" ?  <img className="logo" style={{'height':'4em'}} src={Logo}  alt='Scrirrel Logo'/> : <></> }
                    {story.nsfw  ?  <img className="logo" style={{'height':'3.5em'}} src={NSFW}  alt='Not Safe For Work'/> : <></> }
                    {story.parent === "" ?  <img className="logo" style={{'height':'3.5em'}} src={Beginning}  alt='Opening Chapter'/> : <></> }
                  </div>

                </div>

                <div style={{display:'flex', alignItems:'center', justifyContent:'space-between', margin:' 0 1em', fontSize: '1.2em', color: '#333'}}>
                <h4 ><Link  to='' disabled={!user?.result} style={{textDecoration:'none', color:'inherit', margin:' 0 1em'}}  onClick={() => {
                    dispatch(likeStory(story._id))

                  }}> <FaThumbsUp className='icon' /></Link><Likes/></h4>


                </div>


                <div className='storyview-body'>
                  <ReactMarkdown children={story.body} />
                </div>
                  <h4>Created: {moment(story.createdAt).fromNow()}</h4>
                  {story.tags.map((tag) => `#${tag} `)}

            </div>
          

          

            {modal &&                    
            <div id="modal" className="modal">
            <div className='modal-body'>


                <h1>Delete Story</h1>
                <p>Are you sure you want to delete this story?</p>

                <div >
                <Link style={{textDecoration:'none', margin:'1em'}} to='' onClick ={ () => {setModal(false)}}><a className='button secondary'>Cancel</a></Link>
                <Link style={{textDecoration:'none', margin:'1em'}}  to='' onClick ={ () => {dispatch(deleteStory(story._id))}}><a className='button'>Delete</a></Link>

                </div>
              </div>
            </div> 
            }

  </div>

    
          <div>
          <div>
           <UserView id = {story}/>
        </div>



          </div>
        </>
          }

        </div>

        
      }

    </> 
  )
}

export default StoryView