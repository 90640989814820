import React, {useState,  useEffect} from 'react'
import { useDispatch, useSelector } from 'react-redux'
import './styles.css'
import Story from './Story/Story'
import Pagination from '../Pagination/Pagination'
import { useLocation, useNavigate } from 'react-router-dom'

import { getStories, getStoriesBySearch } from '../../actions/stories'
import Beginning from '../../img/book.png';
import NSFW from '../../img/NSFW.png';

function useQuery(){
  return new URLSearchParams(useLocation().search);
}



const Stories = () => {
  const defaultStories = useSelector((state) => state.stories)
  const [stories,setStories] = useState(useSelector((state) => state.stories))
  const [currentPage, setCurrentPage] = useState(1);
  const [currentStories, setCurrentStories] = useState(stories.slice(0, 12))
  const PageSize = 12;
  const query = useQuery();
  const history = useNavigate();
  const page = query.get('page') | 1;
  const searchQuery = query.get("searchQuery");
  const [search, setSearch] = useState('');
  const [tags, setTags] = useState([]);
  const [tagInput, setTagInput] = useState([]);
  const dispatch = useDispatch();
  const goToTop = () => window.scrollTo(0,0);
  
  const [filteredBeginnings, setFilteredBeginnings] = useState(true);
  const [filteredNSFW, setFilteredNSFW] = useState(true);
  const [updateFilters, setUpdateFilters] = useState(true);

  useEffect(() =>{
    let filtered = defaultStories.filter(story => !story.nsfw)
    setStories(filtered)
  },[defaultStories])
  useEffect(() => {
    const firstPageIndex = (currentPage - 1) * PageSize;
    const lastPageIndex = firstPageIndex + PageSize;
    setCurrentStories(stories.slice(firstPageIndex, lastPageIndex));
  },[stories, currentPage])


  useEffect(() => {
    console.log( filteredBeginnings, filteredNSFW )
    console.log(stories)
    if(!filteredNSFW && !filteredBeginnings){
      let filtered = defaultStories.filter(story => story.lineage.length === 0)
      setStories(filtered);
      console.log(filtered)
    }else if(filteredNSFW && !filteredBeginnings){
      let filtered = defaultStories.filter(story => !story.nsfw && story.lineage.length === 0)
      setStories(filtered);
      console.log(filtered)
    }else if(!filteredNSFW && filteredBeginnings){
      setStories(defaultStories);
    }else{
      setStories(defaultStories.filter(story => !story.nsfw))

    }
    console.log(stories)

  }, [defaultStories, updateFilters, filteredNSFW, filteredBeginnings])


  const handleKeyPress = (e) => {
    if(e.keyCode === 13){
      searchStory();
    }
  }

  const searchStory = () =>{
    if(!search && tags==''){
      dispatch(getStories())
      setUpdateFilters(!updateFilters);
    }else
    if(search.trim() || tags){
      dispatch(getStoriesBySearch({search, tags: tags.join(',')}))
      setUpdateFilters(!updateFilters);
    }else{
      history('/');
    }
          

  }

  const handleAdd = (e)=>{
    if(e.keyCode === 13){
      setTags([...tags, tagInput])
      setTagInput('')
    }
  }

  const handleDelete = (tagToDelete)=>{
    if(tagToDelete){
      setTags(tags.filter((tag) => tag !== tagToDelete))
    }

  }

  const filterStories =  () =>{

   setFilteredBeginnings(!filteredBeginnings)
    if(filteredBeginnings){
      let filtered = stories.filter(story => story.lineage.length === 0)
      setStories(filtered)
    }else{
      if(filteredNSFW){
        let filtered = defaultStories.filter(story => !story.nsfw)
        setStories(filtered)
      }else{
        setStories(defaultStories)

      }
    }

  }

  const filterNSFW =  () =>{
 
   setFilteredNSFW(!filteredNSFW)
    if(filteredNSFW){
      if(!filteredBeginnings){
        let filtered = defaultStories.filter(story => story.lineage.length === 0)
        setStories(filtered)
      }else{
        setStories(defaultStories)
      }
    }else{
      if(filteredBeginnings){
        let filtered = defaultStories.filter(story => !story.nsfw)
        setStories(filtered)
      }else{
        let filtered = stories.filter(story => !story.nsfw)
        setStories(filtered)
      }
    }


  }
 
  return (
    <>
    

    <div style={{display:"flex", flexDirection:"column"}}>
      <div className="search-container">
        <div className="container">
          <div style={{display:"grid", gridTemplateColumns:"repeat(2,1fr)" }}>
          <input type="text" name="searchStories" placeholder='Search Stories' value={search} onChange={(e) => setSearch(e.target.value)} onKeyDown={handleKeyPress}/>
          <input type="text" name="searchTags" placeholder='Search Tags' value={tagInput} onChange={(e)=> setTagInput(e.target.value)} onKeyUp={handleAdd}/>
          <div>
            <div style={{display: 'flex'}}>
            <input type="checkbox" name="beginning" id="beginning" onChange={() => setFilteredBeginnings(!filteredBeginnings)}/>
              <label htmlFor="beginning">Beginning  </label>
              <img  style={{height:'1.8em', marginLeft:'0.2em'}} src={Beginning}  alt='Opening Chapter'/>
            </div>

            <div style={{display: 'flex'}}>
              <input type="checkbox" name="nsfw" id="" onChange={() => setFilteredNSFW(!filteredNSFW)}/>
              <label htmlFor="nsfw">NSFW</label>
              <img  style={{height:'1.8em', marginLeft:'0.2em'}} src={NSFW}  alt='Not Safe For Work'/>
            </div>
            <br/>



          </div>
          <div style={{display:"flex", flexWrap:"wrap", width:"100%", gap:"6px", padding:"0 0 1em 5px "}}>
          {tags.map((tag) =>
          <button style={{width:"30%", border:"1px solid #ccc", borderRadius:"1em", padding:"6px", cursor:"pointer"}} onClick={() => handleDelete(tag)}>
           <a >{tag} x</a>
          </button> 
          )}
          </div>


          </div>
          
          <button className="button" style={{width:"100%", cursor:"pointer"}} onClick={searchStory}>Search</button>

        </div>
      </div>
      {!currentStories.length ? <p>No Stories </p> :

            <>
      <div className = "stories-container">
        {[...currentStories].reverse().map((story) =>(
          <Story story = {story} key = {story._id.toString()} />
        ))}
  
      </div>

        <Pagination
              className="pagination"
              currentPage={currentPage}
              totalCount={stories.length}
              pageSize={PageSize}
              onPageChange={(page) => {
                setCurrentPage(page)
                goToTop()
              }}
            />

            </>
        }

    </div>
      
  </>
  )
}

export default Stories