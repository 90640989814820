import * as api from '../api';

export const login = (form, history, setErrorMessage) => async(dispatch) => {
    try {
        const { data } = await api.logIn(form);

        dispatch({ type: "AUTH", data});
        history('/');
    } catch (error) {
        setErrorMessage("Incorrect Email or Password");
    }
}

export const signup = (form, history, setErrorMessage) => async(dispatch) => {

    try {
        const { data } = await api.signUp(form);
        dispatch({ type: "AUTH", data});

        history('/');
    } catch (error) {
        let message = JSON.stringify(error.response.data.message)
        setErrorMessage(message)
    }
}

export const resetpassword = (email, history) => async(dispatch) => {

    try {
        const { data } = await api.resetPassword({email :email});
        dispatch({ type: "UPDATE", data});

        history('/');
    } catch (error) {
        console.log(error);
    }
}

export const  updatepassword = (password, token, history) => async(dispatch) => {

    try {
        const { data } = await api.updatePassword({password :password, token : token});
        dispatch({ type: "UPDATE", data});

        history('/');
    } catch (error) {
        console.log(error);
    }
}

export const updateInfo = (updated) => async(dispatch) => {

    try {
        console.log(updated.userData);
        const { data } = await api.updateInfo(updated.userData);
        dispatch({ type: "UPDATE", data});
    } catch (error) {
        console.log(error);
    }
}