
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams, useLocation } from 'react-router-dom';

import StoryView from './StoryView/StoryView';
import StoryViewCurrent from './StoryView/StoryViewCurrent';
import WelcomeBanner from '../WelcomeBanner/WelcomeBanner';




const StoryTree = () => {
    const { id } = useParams();
    const [story, setStory] = useState();
    const [lineage, setLineage] = useState(story?.lineage);
    const [isLoaded, setIsLoaded] = useState(false);
    const location = useLocation();
    
    useEffect(() => {
      fetch(`https://scrirrel-db.herokuapp.com/stories/${id}`).then(res => res.json())
      .then(
        (result) => {
          setIsLoaded(true)
          if(!result?.message){
            setStory(result)
            setLineage(story?.lineage)
          }


        },
        (error) =>{
          console.log(error)
        }
      )
    }, [location])
    
  return (
    <div>
      {!story && 
      <>
        <WelcomeBanner big ={"Nothing here..."}></WelcomeBanner>
        <div className='row-section'>

        <div className="storyview-story" style={{textAlign:'center'}}>
                  <p>There was a story here...</p>
                  <p>It's gone now</p>
                </div>
        </div>
      </>

      }
      {story && 
     
      <div>
        <WelcomeBanner big ={story.title + ' by ' + story.name}></WelcomeBanner>
        {story.lineage?.map((id) => (
          <StoryView id = {id} setRootStory = {setStory} key={id.toString()}/>
        ))}
        <StoryViewCurrent  story={story} setRootStory={setStory}/>


      </div>
      
      }

    </div>

  )
}

export default StoryTree