import React, {useEffect, useState} from 'react'
import { Link, BrowserRouter } from 'react-router-dom'

import Story from '../../Stories/Story/Story'

const ChildList = ({parent, setRootStory}) => {
  const [children, setChildren] = useState();
  const [isLoaded, setIsLoaded] = useState(false);
  const [childrenDisplay, setChildrenDisplay] = useState(4)

  useEffect(() => {
    fetch(`https://scrirrel.herokuapp.com/stories/children/${parent._id}`).then(res => res.json())
    .then(
      (result) => {
        setIsLoaded(true);
        setChildren(result)
      },
      (error) =>{
        console.log(error)
      }
    )
  }, [parent])



  return (
    <div>
      {!isLoaded ? 
              <div>
                
              </div>
      : 
      <>
        <div className='child-carousel'>
        
          {children.sort(function(a,b){return b.likes.length - a.likes.length})
          ?.map((child,i) => {

            if(i<childrenDisplay){
              return (
                <Story story = {child} onClick={() => setRootStory(child)} key={child._id}></Story>
           )
            }
              
          })
          }


        </div>
        <div style={{display:"flex", justifyContent:"center"}}>
          {childrenDisplay < children.length &&
              <Link to='' onClick={() => setChildrenDisplay(childrenDisplay + 4)} style={{textDecoration:'none', color:'inherit'}}><h4>Show more</h4></Link>
          }
          {children.length >= 4 && childrenDisplay > children.length &&
              <Link to='' onClick={() => setChildrenDisplay(childrenDisplay  - 4)} style={{textDecoration:'none', color:'inherit'}}><h4>Show less</h4></Link>

          }
        </div>
      
      </>
      }

    </div>
  )
}

export default ChildList