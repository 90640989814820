import React, {useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { updateInfo } from '../../actions/auth';
import { Link } from 'react-router-dom';

import Story from '../Stories/Story/Story';

const Dashboard = () => {
    const [user, setUser] = useState(JSON.parse(localStorage.getItem('profile')).result);
    const [submitted, setSubmitted] = useState(false);
    const [tagline, setTagline] = useState(user.tagline);
    const [about, setAbout] = useState(user.about);
    const [userData, setUserData] = useState({id : user._id, tagline : '', about: ''})
    const [userStories, setUserStories] = useState();
    const dispatch = useDispatch();

    useEffect(() => {
      fetch(`https://scrirrel-db.herokuapp.com/user/${user._id}`).then(res => res.json())
      .then(
        (result) => {
          setTagline(result.tagline);
          setAbout(result.about);

        },
        (error) =>{
          console.log(error)
        }
      )
    }, [submitted])
    
    useEffect(() => {
      fetch(`https://scrirrel-db.herokuapp.com/stories/author/${user._id}`).then(res => res.json())
      .then(
        (result) => {
          console.log(result);
          setUserStories(result)
 
        },
        (error) =>{
          console.log(error)
        }
      )
    }, [])

    const handleSubmit = (e) =>{
      e.preventDefault();
        setAbout(userData.about);
        setTagline(userData.tagline);
        dispatch(updateInfo({userData}));
        clear()
        
    };

    const clear = (e) =>{
      setUserData({id: user._id, tagline : '', about: ''});
    }


  return (
    <>
    <div className='row-section'>
        <div className="container">
            <h2>{user?.name}'s Dashboard</h2>
            <br />
            <p><b>Email:</b> {user?.email}</p>
            <p><b>Tagline:</b> {tagline}</p>
            <p><b>About:</b> {about}</p>
            <br />
            <h3>Update Info</h3>
            <form  onSubmit={handleSubmit}>

              <label htmlFor="tagline"> Tagline:   </label>
              <input type="text" name="tagline" placeholder={tagline} value={userData.tagline} onChange={(e) => setUserData({ ...userData, tagline: e.target.value})}/>
              <label htmlFor="tagline"> About:   </label>
              <input type="text" name="about" placeholder={about} value={userData.about} onChange={(e) => setUserData({ ...userData, about: e.target.value})}/>

              <input className="button secondary" type="submit" value="Update Info"/>
            </form>
            
        </div>



    </div>
    <div className='children-form'>
      <div className='child-carousel'>
              {userStories?.map((story) =>{
                if(story.title){
                  return(
                  <Story story = {story} key = {story._id}></Story>

                  )
                }
              })}
        
          
      </div>
    </div>
    </>
  )
}

export default Dashboard