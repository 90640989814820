import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux';

import { createComment } from '../../../actions/comments';

const CommentForm = ({story}) => {
    const user = JSON.parse(localStorage.getItem('profile'));
    const [formMessage, setFormMessage] = useState();
    const [commentData, setCommentData] = useState({
      message: '', authorName: user?.result?.name, authorID: user?.result?._id, storyID: story._id
    });  

    const dispatch = useDispatch();

    const handleSubmit = (e) =>{
    
      e.preventDefault();
      if(commentData.message.length ){
        dispatch(createComment(commentData));
        clear()
      }else{
        setFormMessage("Please write a comment before submitting.")
      }
  
    };

    const clear = (e) =>{
      setCommentData({message: ''});
    }

    if(!user?.result?.name){
        return (
            <div >
            <h4>Please log in to comment.</h4>
            </div>
        )
        }
  return (
    <div>
        <h4 className="error" style={{margin:"0 0 1em 0"}}>{formMessage}</h4>

      <h4>Comment on {story.title}</h4>
      <form autoComplete='off' noValidate className='form' onSubmit={handleSubmit}>


        <label htmlFor="message"> Message: </label>
        <textarea  className="tags" name="searchTags"  value={commentData.message} onChange={(e)=> setCommentData({ ...commentData, message: e.target.value})}  />
        
        <input className="button secondary" type="submit" value="Submit"/>
              
      </form>
    </div>
  )
}

export default CommentForm