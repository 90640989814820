import React, {useEffect, useState} from 'react'
import { useDispatch } from 'react-redux';
import { useNavigate, useParams} from 'react-router-dom';
import { updatepassword } from '../../actions/auth';
import WelcomeBanner from '../WelcomeBanner/WelcomeBanner';


const UpdatePassword = () => {
    const { token } = useParams();

    const [password, setPassword] = useState('');

    const dispatch = useDispatch();
    const history = useNavigate();

    const handleSubmit = (e) => {
        e.preventDefault();
        
        dispatch(updatepassword(password, token,history))

      };


  return (
    <>
    <WelcomeBanner/>
    <div className='row-section'>
      <div className='container'>

        <h2>Choose a New Password</h2>

        <form  onSubmit={handleSubmit}>
          <div >
            <div style={{display:"flex", gap:"0.5em"}}>
              <input name="password" placeholder="New Password" onChange={(e) => setPassword(e.target.value)} type="password" />
            </div>
    
          </div>
           

          <button className="button secondary" style={{width:"100%"}} type="submit" >Reset Password</button>

        </form>

      </div>
  </div>
  </>
  )
}

export default UpdatePassword