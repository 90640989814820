import React from 'react'
import Form from '../Form/Form'


import WelcomeBanner from '../WelcomeBanner/WelcomeBanner'


const AddNew = () => {
  return (
      <>
        <WelcomeBanner/>
        <div className="row-section">
          <Form/>
      </div>
      </>

  )
}

export default AddNew