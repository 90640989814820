import React, {useState, useEffect} from 'react'
import { Link , useLocation, useNavigate} from 'react-router-dom'
import { useDispatch } from 'react-redux';
import {FaBars} from 'react-icons/fa'
import decode from 'jwt-decode';
import './styles.css'

import Logo from '../../img/logo.png';

export const Navbar = () => {
  const [user, setUser] = useState(JSON.parse(localStorage.getItem('profile')));
  const [isNavClosed, setIsNavClosed] = useState(true);
  const dispatch = useDispatch();
  const location = useLocation();
  const history = useNavigate();

  const logout = () => {
    dispatch({ type: "LOGOUT" });

    history('/auth');

    setUser(null);
  };

  useEffect(() => {
    const token = user?.token;

    if (token) {
      const decodedToken = decode(token);

      if (decodedToken.exp * 1000 < new Date().getTime()) logout();
    }
    setUser(JSON.parse(localStorage.getItem('profile')));
  }, [location]);



  return (
    <nav className='nav'>
        <div className="nav-group">
          <div className='brand'>
                  <Link className="nav-link" to="/" >
                      <img className="logo" src={Logo}  alt='Scrirrel Logo'/>
                  </Link>
          </div>
          <Link to='/' onClick={() => {setIsNavClosed(!isNavClosed)}}><FaBars className='nav-link ham'/></Link>
        </div>

        <div className={isNavClosed ? "nav-items closed" : "nav-items"}>
                <Link className="nav-link" to="/about" >
                    <p >About</p>
                </Link>
                <Link className="nav-link" to="/browse" >
                    <p >Browse</p>
                </Link>
                <Link className="nav-link" to="/create" >
                    <p >Create</p>
                  </Link>

          {user?.result ? (
          < >
            <Link className="nav-link" to="/dashboard"><h4 >{user?.result.name}</h4></Link>
            <Link className="nav-link" to="/" onClick={logout}><p className='button'>Logout</p></Link>
          </>
         ) : (
          <>
            <Link className="nav-link" to="/auth" state={{direct:"login"}}>
              <p className='button secondary'>Log In</p>
            </Link>

            <Link className="nav-link" to="/auth" state={{direct:"signup"}}>
            <p className='button'>Sign Up</p>
            </Link>     
          </>

        )}
                
        </div>

    </nav>
  )
}
