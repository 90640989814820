import React, { useEffect, useState } from 'react'
import ReactMarkdown from 'react-markdown'
import { useSelector , useDispatch} from 'react-redux'
import { Link, useNavigate } from 'react-router-dom'
import { FaTrashAlt, FaThumbsUp, FaLock } from 'react-icons/fa';
import moment from 'moment'
import './style.css'

import { likeStory, deleteStory, lockStory } from '../../../actions/stories'
import { getComments, createComment, deleteComment } from '../../../actions/comments'

import Form from '../../Form/Form'
import ChildList from '../ChildList/ChildList'
import UserView from './UserView';
import CommentForm from './CommentForm';
import Story from '../../Stories/Story/Story';


import Logo from '../../../img/logo.png';
import NSFW from '../../../img/NSFW.png';
import Beginning from '../../../img/book.png';


const StoryView = ({story, setRootStory}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const user = JSON.parse(localStorage.getItem('profile'));
  const [storyUser, setStoryUser] = useState()
  const [comments, setComments] = useState()
  const [modal, setModal] = useState(false);
  const [lockModal, setLockModal] = useState(false);
  const [showComments, setShowComments] = useState(false);
  const [userStories, setUserStories] = useState();

  useEffect(() => {
    fetch(`https://scrirrel-db.herokuapp.com/comments/${story._id}`).then(res => res.json())
    .then(
      (result) => {
        setComments(result)

      },
      (error) =>{
        console.log(error)
      }
    )
  },[story, storyUser])



  useEffect(() => {
    fetch(`https://scrirrel-db.herokuapp.com/user/${story.author}`).then(res => res.json())
    .then(
      (result) => {
        setStoryUser(result)

      },
      (error) =>{
        console.log(error)
      }
    )
  }, [story])

  useEffect(() => {
    fetch(`https://scrirrel-db.herokuapp.com/stories/author/${story.author}`).then(res => res.json())
    .then(
      (result) => {
        console.log(result);
        setUserStories(result)

      },
      (error) =>{
        console.log(error)
      }
    )
  }, [])



  const Likes = () => {
    if (story.likes.length > 0) {
      return story.likes.find((like) => like === user?.result?._id)
        ? (
          <>

          <span className='highlighted'>{story.likes.length > 2 ? `You and ${story.likes.length - 1} others` : `${story.likes.length}` }</span>

          
          </>
        ) : (
          <>        
          <span className='highlighted'>{story.likes.length}</span>
          </>
        );
    }

    return <><span className='highlighted'>{story.likes.length}</span></>;
  };



  return (
    
    <>
      <div className='storyview-section' >
          <div >


          <div className="storyview-story">
              <div style={{display: 'flex', justifyContent: 'space-between'}}>

                <h3><Link to={`/story/${story._id}`}  style={{ textDecoration: 'none', color:'inherit' }}>{story.title} </Link></h3>

                <div>
                  {story.author === "62f62dc4882fb82cac8ffa61" ?  <img className="logo" style={{'height':'4em'}} src={Logo}  alt='Scrirrel Logo'/> : <></> }
                  {story.nsfw  ?  <img className="logo" style={{'height':'3.5em'}} src={NSFW}  alt='Not Safe For Work'/> : <></> }
                  {story.parent === "" ?  <img className="logo" style={{'height':'3.5em'}} src={Beginning}  alt='Opening Chapter'/> : <></> }
                </div>

              </div>


              <div style={{display:'flex', alignItems:'center', justifyContent:'space-between', margin:' 0 1em 0 0', fontSize: '1.2em', color: '#333'}}>
                <h4 ><Link  to='' disabled={!user?.result} style={{textDecoration:'none', color:'inherit', margin:' 0 1em'}}  onClick={() => {
                  dispatch(likeStory(story._id))
                  

                }}> <FaThumbsUp className='icon' /></Link><Likes/></h4>

                <div style={{display:"flex", alignItems:"center", gap:"1em"}}>
                
                  {(user?.result?._id === story?.author) && !story?.locked &&  (
                    
                    <Link  to='' style={{textDecoration:'none', color:'inherit'}}  onClick={() => {
                      setLockModal(true)

                  }}> <FaLock className='icon' /></Link> 
                  )}

                  {(user?.result?._id === story?.author) && story?.locked &&  (
                    <>
                     <h4 style={{color:"#c3c3c3"}}>Locked</h4> 
                    </>
                   
                  )}


                  {(user?.result?._id === story?.author) && (
                    
                    <Link  to='' style={{textDecoration:'none', color:'inherit'}}  onClick={() => {
                      setModal(true)

                  }}> <FaTrashAlt className='icon' /></Link> 
                  )}
                </div>
              </div>


              <div className='storyview-body'>
                <ReactMarkdown children={story.body} />
              </div>
                <h4>Created: {moment(story.createdAt).fromNow()}</h4>
                {story.tags.map((tag) => `#${tag} `)}
                <Link  to='' style={{textDecoration:'none', color:'inherit', display:'flex' , justifyContent: 'end'}}  onClick={() => {
                      setShowComments(!showComments)

                  }}> <h4>Show comments</h4></Link> 


          </div>


         


  

          {modal &&                    
          <div id="modal" className="modal">
           <div className='modal-body'>


              <h1>Delete Story</h1>
              <p>Are you sure you want to delete this story?</p>

              <div >
              <Link style={{textDecoration:'none', margin:'1em'}} to='' onClick ={ () => {setModal(false)}}><a className='button secondary'>Cancel</a></Link>
              <Link style={{textDecoration:'none', margin:'1em'}}  to='' onClick ={ () => {
                dispatch(deleteStory(story._id));
                setModal(false);
                }}><a className='button'>Delete</a></Link>

              </div>
            </div>
           </div> 
           }

        

          {lockModal &&                    
          <div id="modal" className="modal">
           <div className='modal-body'>


              <h1>Lock Story?</h1>
              <p>Are you sure you want to lock this story?</p>
              <p>This means nobody can respond to your story, and it will be marked as an ending.</p>

              <div >
              <Link style={{textDecoration:'none', margin:'1em'}} to='' onClick ={ () => {setLockModal(false)}}><a className='button secondary'>Cancel</a></Link>
              <Link style={{textDecoration:'none', margin:'1em'}}  to='' onClick ={ () => {
                dispatch(lockStory(story._id))
                setLockModal(false)
                
                }}><a className='button'>Lock</a></Link>

              </div>
            </div>
           </div> 
           }  

<div >
  {showComments && 
            <div className="comment-section">
              {comments && <h4>Comments</h4>}
              {comments.length === 0 && <h5>No comments yet...</h5>}
              {comments?.map((comment) => (
                <div className="comment-body">
                  <h4>{comment?.authorName}</h4>
                <p>{comment?.message}</p>
                <h4>{moment(comment.createdAt).fromNow()}</h4>
                </div>


              ))}
              <div className="comment-form">
                <CommentForm story = {story}/>
              </div>
            </div>


  }
             </div>

      </div>
       
        <div className="profile-container">
          <div className="storyview-profile">
            <h3 className='author-name'>{storyUser?.name}</h3>
            {storyUser?.tagline && <h4>{storyUser?.tagline}</h4>}
            {storyUser?.about &&   <p>{storyUser?.about}</p>}
              
          </div>

          <div className="recommended-container">
          <h2>Another story from {storyUser?.name}</h2>

          {userStories?.map((story,i) =>{
                if(story.title && i < 1){
                  return(
                  <div style={{"padding":"1em 0 "}}>
                  <Story story = {story} key = {story.id}></Story>
                  </div>
                  )
                }
              })}
          </div>



        </div>






      </div>

      
      {story?.locked ?
      <>
            <div className="children-form">
           <ChildList parent= {{...story}} setRootStory={setRootStory}/>

             </div>
                  <div style={{display:'flex', justifyContent:"center", margin:"4em"}}>
                    <div className='container' style={{textAlign:"center"}}>
                    <h3>The End</h3> 
                    <p>This story has been locked - sadly all stories must come to an end.</p>
                    <Link to='/create' className='primary' style={{textDecoration:'none'}}><h4>Write your own story</h4></Link>

                    </div>
                  </div>
      </>
                  
      :
      <div className="children-form">
        <ChildList parent= {{...story}} setRootStory={setRootStory}/>
        <div style={{padding:"4em 0 4em 0 "}}>
              <Form story= {story} ></Form>
            </div>
      </div>}


    </> 
  )
}

export default StoryView