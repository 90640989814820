import React from 'react'
import ReactMarkdown from 'react-markdown'
import { Link } from 'react-router-dom'
import './styles.css'

import Logo from '../../../img/logo.png';
import NSFW from '../../../img/NSFW.png';
import Beginning from '../../../img/book.png';

const Story = ({story}) => {

  return (
    <Link className="story-link" to={`/story/${story._id}`} style={{ textDecoration: 'none', color:'inherit' }}>
      <div className='story-card' >
        <div style={{display: 'flex', justifyContent: 'space-between',}}>
         <h3>{story.title} </h3>
         <div>
          {story.author === "62f62dc4882fb82cac8ffa61" ?  <img className="logo" style={{'height':'4em'}} src={Logo}  alt='Scrirrel Logo'/> : <></> }
          {story.nsfw  ?  <img className="logo" style={{'height':'3.5em'}} src={NSFW}  alt='Not Safe For Work'/> : <></> }
          {story.parent === "" ?  <img className="logo" style={{'height':'3.5em'}} src={Beginning}  alt='Opening Chapter'/> : <></> }
         </div>

        </div>
        <div style={{display: "flex", justifyContent: "space-between"}}>
         <h4>by <span className='author-name'>{story.name}</span></h4>
         <h4 >Likes: <span className='highlighted'>{story.likes.length}</span></h4>
        </div>

        <div className='story-body'>
          
          <ReactMarkdown className="get-clamped" children={story.body}/>
        </div>
        <div className="tags">

            {story.tags.map((tag) => <span className="tag" key={tag}>#{tag} </span> )}
        </div>
      </div>
    </Link>
  )
}

export default Story