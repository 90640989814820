import React, {useEffect, useState} from 'react'
import { useLocation } from 'react-router-dom'


const UserView = ({id}) => {

    const [user, setUser] = useState()
    const location = useLocation();
    
    useEffect(() => {
        fetch(`https://scrirrel-db.herokuapp.com/user/${id.author}`).then(res => res.json())
        .then(
          (result) => {
            setUser(result)

          },
          (error) =>{
            console.log(error)
          }
        )
      }, [location])


  return (
    <div className="storyview-profile">
        <h3 className='author-name'>{user?.name}</h3>
        {user?.tagline && <h4>{user?.tagline}</h4>}
        {user?.about &&   <p>{user?.about}</p>}

        
    </div>
  )
}

export default UserView